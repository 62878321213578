import React from "react";
import classNames from "classnames";
import { SectionSplitProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import Image from "../elements/Image";

const propTypes = {
  ...SectionSplitProps.types,
};

const defaultProps = {
  ...SectionSplitProps.defaults,
};

const FeaturesSplit = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {
  const outerClasses = classNames(
    "features-split section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "features-split-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const splitClasses = classNames(
    "split-wrap",
    invertMobile && "invert-mobile",
    invertDesktop && "invert-desktop",
    alignTop && "align-top"
  );

  const sectionHeader = {
    title: "Section One Title",
    paragraph:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus at elit eu metus ultricies condimentum. Praesent ultrices laoreet lectus, vitae blandit risus semper vel. Nam risus enim, molestie mollis malesuada nec, mollis sed sapien. Fusce mattis diam nulla, a molestie risus mattis vel. Nunc ultricies justo turpis, eget porttitor tellus commodo in.",
  };

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={splitClasses}>
            <div className="split-item">
              <div
                className="split-item-content center-content-mobile reveal-from-left"
                data-reveal-container=".split-item"
              >
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Small Title Text 1
                </div>
                <h3 className="mt-0 mb-12">Big Title Text 1</h3>
                <p className="m-0">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Vivamus at elit eu metus ultricies condimentum. Praesent
                  ultrices laoreet lectus, vitae blandit risus semper vel. Nam
                  risus enim, molestie mollis malesuada nec, mollis sed sapien.
                  Fusce mattis diam nulla, a molestie risus mattis vel.
                </p>
              </div>
              <div
                className={classNames(
                  "split-item-image center-content-mobile reveal-from-bottom",
                  imageFill && "split-item-image-fill"
                )}
                data-reveal-container=".split-item"
              >
                <Image
                  src={require("./../../assets/images/placeholder.png")}
                  alt="Features split 01"
                  width={528}
                  height={396}
                />
              </div>
            </div>

            <div className="split-item">
              <div
                className="split-item-content center-content-mobile reveal-from-right"
                data-reveal-container=".split-item"
              >
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Small Title Text 2
                </div>
                <h3 className="mt-0 mb-12">Big Title Text 2</h3>
                <p className="m-0">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Vivamus at elit eu metus ultricies condimentum. Praesent
                  ultrices laoreet lectus, vitae blandit risus semper vel. Nam
                  risus enim, molestie mollis malesuada nec, mollis sed sapien.
                </p>
              </div>
              <div
                className={classNames(
                  "split-item-image center-content-mobile reveal-from-bottom",
                  imageFill && "split-item-image-fill"
                )}
                data-reveal-container=".split-item"
              >
                <Image
                  src={require("./../../assets/images/placeholder.png")}
                  alt="Features split 02"
                  width={528}
                  height={396}
                />
              </div>
            </div>

            <div className="split-item">
              <div
                className="split-item-content center-content-mobile reveal-from-left"
                data-reveal-container=".split-item"
              >
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Small Title Text 3
                </div>
                <h3 className="mt-0 mb-12">Big Title Text 3</h3>
                <p className="m-0">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Vivamus at elit eu metus ultricies condimentum. Praesent
                  ultrices laoreet lectus, vitae blandit risus semper vel. Nam
                  risus enim, molestie mollis malesuada nec, mollis sed sapien.
                </p>
              </div>
              <div
                className={classNames(
                  "split-item-image center-content-mobile reveal-from-bottom",
                  imageFill && "split-item-image-fill"
                )}
                data-reveal-container=".split-item"
              >
                <Image
                  src={require("./../../assets/images/placeholder.png")}
                  alt="Features split 03"
                  width={528}
                  height={396}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;
